import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const CTAButton = styled.button`
  padding: 12px 24px;
  background: #7bc8a8;
  border-radius: 10px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  
  color: #fdfdfd;
  border: none;
  @media screen and (min-width: 950px){
    padding: 16px 24px;
  }
`

const Button = ({buttonTitle}) => <CTAButton>{buttonTitle}</CTAButton>

Button.propTypes = {
    buttonTitle: PropTypes.string,
  }
  
  Button.defaultProps = {
    buttonTitle: ``,
  }

export default Button
