import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import LogoImg from "./img/logo-img"
import CTA from "./cta-button"

const HeaderMobile = styled.header`
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: #fdfdfd;
  padding: 16px 24px;

  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 450px) {
    padding: 16px 32px;
  }
  @media screen and (min-width: 500px) {
    padding: 16px 48px;
  }
  @media screen and (min-width: 650px) {
    padding: 24px 64px;
  }
  @media screen and (min-width: 800px) {
    padding: 24px 72px;
  }
  @media screen and (min-width: 950px) {
    display: none;
  }
`
const HeaderDesktop = styled.header`
  display: none;
  position: sticky;
  background-color: #fdfdfd;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 950px) {
    padding: 20px 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (min-width: 950px) {
    padding: 20px 96px;
  }
`

const HeaderContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 6;
  margin: 0;
  padding: 0;
`

const MenuContainer = styled.div`
  display: none;
  a:hover {
    text-decoration: underline;
  }
  @media screen and (min-width: 950px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }
  @media screen and (min-width: 1050px) {
    width: 40%;
  }
  @media screen and (min-width: 1200px) {
    width: 35%;
  }
`

const LogoContainer = styled.div`
  a div {
    width: 32px;
    margin-right: 8px;
  }
  a {
    display: flex;
    flex-direction: row;
    align-items: center;

    text-decoration: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #252525;
  }
  @media screen and (min-width: 500px) {
    a div {
      width: 48px;
    }
  }
`

const Hamburger = styled.button`
  position: absolute;
  z-index: 10;
  right: 24px;
  width: 24px;
  height: 24px;

  appearance: none;
  background: none;
  padding: 0;
  border: 0;

  svg {
    width: 24px;
  }
  @media screen and (min-width: 450px) {
    right: 32px;
  }
  @media screen and (min-width: 500px) {
    right: 48px;
    width: 32px;
    height: 32px;
    svg {
      width: 32px;
    }
  }
  @media screen and (min-width: 650px) {
    right: 64px;
  }
  @media screen and (min-width: 800px) {
    right: 72px;
  }
`
const CrossButton = styled.button`
  position: absolute;
  z-index: 10;
  right: 24px;
  width: 24px;
  height: 24px;

  appearance: none;
  background: none;
  padding: 0;
  border: 0;

  svg {
    width: 24px;
  }
  @media screen and (min-width: 450px) {
    right: 32px;
  }
  @media screen and (min-width: 500px) {
    right: 48px;
    width: 32px;
    height: 32px;
    svg {
      width: 32px;
    }
  }
  @media screen and (min-width: 650px) {
    right: 64px;
  }
  @media screen and (min-width: 800px) {
    right: 72px;
  }
`

const MobileMenuContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px 32px;
  background-color: #fdfdfd;
  overflow: hidden;
`

const PseudoButton = styled.button`
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background: none;
`

const Header = ({ siteTitle }) => {
  const [clicked, setClick] = useState(false)
  const burgerClick = !clicked ? "burger-active" : "burger-not-active"
  const crossClick = !clicked ? "cross-not-active" : "cross-active"
  const handleClick = () => {
    setClick(!clicked)
    const html = document.querySelector("html")
    clicked ? (html.style.overflow = null) : (html.style.overflow = "hidden")
  }
  return (
    <HeaderContainer>
      <HeaderMobile>
        <LogoContainer>
          <Link to="/">
            <div>
              <LogoImg />
            </div>
            {siteTitle}
          </Link>
        </LogoContainer>
        <Hamburger onClick={handleClick} className={burgerClick}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12H21"
              stroke="#305244"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 6H17"
              stroke="#305244"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 18H21"
              stroke="#305244"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Hamburger>
        <CrossButton onClick={handleClick} className={crossClick}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.63599 5.63604L18.3639 18.364"
              stroke="#305244"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.63599 18.364L18.3639 5.63604"
              stroke="#305244"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </CrossButton>
        <CSSTransition
          in={clicked}
          timeout={300}
          classNames="is-active"
          unmountOnExit
          onEnter={() => setClick(true)}
          onExited={() => setClick(false)}
        >
          <MobileMenuContainer>
            <PseudoButton onClick={handleClick}>
              <Link to="/">
                <h3 className="header-text">Home</h3>
              </Link>
            </PseudoButton>
            <PseudoButton onClick={handleClick}>
              <Link to="/products" onClick={() => setClick(false)}>
                <h3 className="header-text">Products</h3>
              </Link>
            </PseudoButton>
            <PseudoButton onClick={handleClick}>
              <Link to="/about-us" onClick={() => setClick(false)}>
                <h3 className="header-text">About Us</h3>
              </Link>
            </PseudoButton>
            <PseudoButton onClick={handleClick}>
              <Link to="/contact-us" onClick={() => setClick(false)}>
                <CTA buttonTitle={"Contact Us"} />
              </Link>
            </PseudoButton>
          </MobileMenuContainer>
        </CSSTransition>
      </HeaderMobile>

      <HeaderDesktop>
        <LogoContainer>
          <Link to="/">
            <div>
              <LogoImg />
            </div>
            {siteTitle}
          </Link>
        </LogoContainer>
        <MenuContainer>
          <Link to="/">Home</Link>
          <Link to="/products">Products</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/contact-us">
            <CTA buttonTitle={"Contact Us"} />
          </Link>
        </MenuContainer>
      </HeaderDesktop>
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
