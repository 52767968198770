import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import LogoImg from "./img/logo-img"

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;

  background: #fdfdfd;

  @media screen and (min-width: 450px) {
    padding: 32px 32px;
  }
  @media screen and (min-width: 500px) {
    padding: 32px 48px;
  }
  @media screen and (min-width: 650px) {
    padding: 32px 64px;
  }
  @media screen and (min-width: 800px) {
    padding: 32px 72px;
  }
  @media screen and (min-width: 950px) {
    padding: 32px 80px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "logo logo logo"
      "btg mnd nav"
      "bottom bottom bottom";
    column-gap: 16px;
  }
  @media screen and (min-width: 1050px) {
    padding: 32px 96px;
  }
`
const LogoContainer = styled.div`
  a div {
    width: 32px;
    margin-right: 8px;
  }
  a {
    display: flex;
    flex-direction: row;
    align-items: center;

    text-decoration: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;

    color: #252525;
  }
  @media screen and (min-width: 950px) {
    grid-area: logo;
    a {
      font-size: 1.125rem;
    }
    a div {
      width: 48px;
    }
  }
  @media screen and (min-width: 1050px) {
    a {
      font-size: 1.5rem;
    }
    a div {
      width: 64px;
    }
  }
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  a:hover {
    text-decoration: underline;
  }
  @media screen and (min-width: 950px) {
    grid-area: nav;
    justify-self: end;
    align-self: center;
    width: 80%;
  }
`

const ContactContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h4 {
    color: #305244;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    svg {
      width: 16px;
    }
    p {
      margin-left: 4px;
      line-height: 0;
    }
  }
  @media screen and (min-width: 410px) {
    h4,
    p {
      font-size: 1.125rem !important;
    }
    #copyright {
      font-size: 0.875rem !important;
    }
  }
  @media screen and (min-width: 500px) {
    h4,
    p {
      font-size: 1.125rem !important;
    }
    #copyright {
      font-size: 0.875rem !important;
    }
  }
  @media screen and (min-width: 750px) {
    h4,
    p {
      font-size: 1.125rem !important;
    }
    #copyright {
      font-size: 0.875rem !important;
    }
  }
  @media screen and (min-width: 900px) {
    h4,
    p {
      font-size: 1.125rem !important;
    }
    #copyright {
      font-size: 0.875rem !important;
    }
  }
  @media screen and (min-width: 950px) {
    &:first-child {
      grid-area: btg;
    }
    &:first-child {
      grid-area: mnd;
    }
    #copyright {
      font-size: 0.875rem !important;
    }
  }
  @media screen and (min-width: 1050px) {
    h4,
    p {
      font-size: 1.125rem !important;
    }
    #copyright {
      font-size: 0.875rem !important;
    }
  }
`

//Reminder: use 'display: grid' on desktop version for this
const AddressContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  hr {
    background: #305244;
    border: none;
    height: 4px;
    width: 100%;
  }
  p {
    margin-top: 16px;
  }
  p:last-child {
    font-size: 0.75rem !important;
    color: #7a7a7a;
  }
  @media screen and (min-width: 950px) {
    grid-area: bottom;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "line line"
      "btg copy"
      "mnd copy";
    hr {
      grid-area: line;
    }
    p:first-child {
      grid-area: btg;
    }
    p:nth-child(2) {
      grid-area: mnd;
    }
    p:last-child {
      grid-area: copy;
      align-self: start;
      justify-self: end;
    }
  }
`

const Footer = ({ siteTitle }) => (
  <FooterContainer>
    <LogoContainer>
      <Link to="/">
        <div>
          <LogoImg style={{ zIndex: "0" }} />
        </div>
        {siteTitle}
      </Link>
    </LogoContainer>
    <MenuContainer>
      <Link to="/">Home</Link>
      <Link to="/products">Products</Link>
      <Link to="/about-us">About Us</Link>
      <Link to="/contact-us">Contact Us</Link>
    </MenuContainer>
    <ContactContainer>
      <h4>Batangas</h4>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 2.66675H2.66659C1.93325 2.66675 1.33992 3.26675 1.33992 4.00008L1.33325 12.0001C1.33325 12.7334 1.93325 13.3334 2.66659 13.3334H13.3333C14.0666 13.3334 14.6666 12.7334 14.6666 12.0001V4.00008C14.6666 3.26675 14.0666 2.66675 13.3333 2.66675ZM13.3333 5.33341L7.99992 8.66675L2.66659 5.33341V4.00008L7.99992 7.33341L13.3333 4.00008V5.33341Z"
            fill="black"
          />
        </svg>
        <p>vens_solis@yahoo.com</p>
      </div>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
            fill="black"
          />
        </svg>
        <p>+(043) 740-7969</p>
      </div>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
            fill="black"
          />
        </svg>
        <p>+63 917-505-2053</p>
      </div>
    </ContactContainer>
    <ContactContainer>
      <h4>Mindoro</h4>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 2.66675H2.66659C1.93325 2.66675 1.33992 3.26675 1.33992 4.00008L1.33325 12.0001C1.33325 12.7334 1.93325 13.3334 2.66659 13.3334H13.3333C14.0666 13.3334 14.6666 12.7334 14.6666 12.0001V4.00008C14.6666 3.26675 14.0666 2.66675 13.3333 2.66675ZM13.3333 5.33341L7.99992 8.66675L2.66659 5.33341V4.00008L7.99992 7.33341L13.3333 4.00008V5.33341Z"
            fill="black"
          />
        </svg>
        <p>king.alton@yahoo.com</p>
      </div>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
            fill="black"
          />
        </svg>
        <p>+(043) 441-3032</p>
      </div>
    </ContactContainer>
    <AddressContainer>
      <hr />
      <p>Brgy. Dumantay, Batangas City, Philippines 4200</p>
      <p>Kaimito St., Lalud, Calapan City Oriental Mindoro</p>
      <p id="copyright">King Alton All Rights Reserved 2020</p>
    </AddressContainer>
  </FooterContainer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
